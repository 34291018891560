/* Landing Page */

/* Extra small devices (phones, 608px and down) */
.landing-container {
  position: absolute;
  top: 66px;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: auto;
}
.landing-1 {
  height: 450px;
  width: 100%;
  border-bottom: 3px solid #d60909;
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.landing-1 .main-header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
}
.landing-1 .main-header .id {
  color: #d60909;
}
.landing-1 .light-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 577px;
  z-index: 2;
}
.landing-1-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin-top: 1rem;
  padding: 0.5rem;
}
.landing-1-inner .img {
  grid-column: 1 / 2;
  grid-row: span 2;
  justify-self: flex-start;
  align-self: center;
  /* margin-left: 0.5rem; */
}
.landing-1-inner .img img {
  width: 140px;
  height: 290px;
}
.landing-1-inner .text-1 {
  grid-column: 2 / 3;
  grid-row: 1;
  align-self: flex-start;
  justify-self: flex-start;
  width: 95%;
  display: flex;
  flex-wrap: wrap;
  margin-left: 0.5rem;
}
.landing-1-inner .text-2 {
  grid-column: 2 / 3;
  grid-row: 2;
  align-self: flex-start;
  justify-self: flex-start;
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  margin-left: 0.5rem;
}
.landing-2-header,
.landing-3-header,
.landing-4-header {
  width: 100%;
  font-size: 1.7rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  color: #fff;
  background-color: #242424;
  border-bottom: 3px solid #d60909;
}
.landing-2-header .id,
.landing-3-header .id,
.landing-4-header .id {
  color: #d60909;
}
.landing-3-header,
.landing-4-header {
  border-top: 3px solid #d60909;
}
.landing-2 {
  background: url("../components/images/landing_images/man-wearing-white-sweater-and-black-shorts-about-to-run-936094\ \(1\).jpg")
    no-repeat center center/cover;
  height: 580px;
  width: 100%;
  z-index: 1;
  position: relative;
}
.landing-2 .light-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 580px;
  z-index: 2;
  background-color: rgba(248, 245, 245, 0.9);
}
.landing-2-inner,
.landing-3-inner,
.landing-4-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  color: #242424;
  z-index: 3;
  position: relative;
}
.landing-2-inner .btn,
.landing-3-inner .btn,
.landing-4-inner .btn {
  margin-top: 2rem;
  position: absolute;
  bottom: 70px;
  z-index: 5;
}
.landing-3 {
  background: url("../components/images/landing_images/action-adult-american-football-athletes-260606.jpg");
  height: 600px;
  width: 100%;
  z-index: 1;
  position: relative;
}
.landing-3 .light-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 600px;
  z-index: 2;
  background-color: rgba(248, 245, 245, 0.9);
}
.landing-4 {
  background: url("../components/images/landing_images/grandstand.jpg");
  height: 600px;
    width: 100%;
    z-index: 1;
    position: relative;
}
.swiper {
  height: 100%;
  width: 100%;
}
.swiper .swiper-slide {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.swiper .swiper-button-prev,
.swiper .swiper-button-next {
  color: #9b9696;
  display: none;
}
span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #d60909;
}
.swiper-pagination {
  margin-bottom: 1rem;
}
.slide-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  justify-self: flex-start;
  align-items: center;
}
.slide-content .prompt {
  justify-self: flex-end;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #d60909;
  margin-top: 2rem;
}
.slide-content .prompt i {
  margin-left: 0.5rem;
}
.ath-profile-ex,
.pitch-ex,
.ath-search-ex,
.picks-ex,
.org-coaches-ex {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0 0.5rem;
}
.sport-cred-ex {
  width: 110%;
  height: 100%;
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  padding: 0;
  justify-content: flex-end;
}
.coach-prof-ex,
.groups-ex,
.org-prof-ex,
.org-notice-ex {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.coach-prof-ex .text,
.groups-ex .text,
.org-prof-ex .text,
.org-notice-ex .text {
  width: 100%;
  max-width: 360px;
  margin: 2rem 0;
}
.coach-prof-ex .text p,
.groups-ex .text p,
.org-prof-ex .text p,
.org-notice-ex .text p {
  margin-top: 1rem;
}
.coach-prof-ex .img,
.org-prof-ex .img {
  width: 350px;
  height: 220px;
}
.groups-ex .img,
.org-notice-ex .img {
  width: 450px;
  height: 280px;
  margin-top: 1rem;
}
.phys-tests-ex,
.pitch-ex,
.ath-search-ex,
.picks-ex,
.org-coaches-ex {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0;
  align-items: flex-start;
}
.ath-search-ex .img.left,
.phys-tests-ex .img.left,
.pitch-ex .img.left,
.picks-ex .img.left,
.org-coaches-ex .img.left {
  grid-column: 1 / 2;
  grid-row: 2;
  justify-self: center;
}
.ath-search-ex .img.right,
.phys-tests-ex .img.right,
.pitch-ex .img.right,
.picks-ex .img.right,
.org-coaches-ex .img.right {
  grid-column: 2 / 3;
  grid-row: 2;
  justify-self: center;
}
.ath-profile-ex .img,
.sport-cred-ex .img,
.phys-tests-ex .img,
.pitch-ex .img,
.ath-search-ex .img,
.picks-ex .img,
.org-coaches-ex .img {
  height: 300px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}
.sport-cred-ex .img {
  margin-top: 3rem;
}
.ath-profile-ex .img {
  margin-top: 3rem;
  height: 320px;
}
.ath-profile-ex .text {
  width: 45%;
  max-width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
}

.sport-cred-ex .text {
  grid-column: 1 / 2;
  width: 80%;
  justify-self: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  align-items: flex-start;
  margin-top: 1.5rem;
}
.phys-tests-ex .text,
.pitch-ex .text,
.ath-search-ex .text,
.picks-ex .text,
.org-coaches-ex .text {
  width: 100%;
  max-width: 410px;
  grid-column: 1 / 4;
  grid-row: 1;
  justify-self: center;
  align-self: flex-start;
}
.ath-search-ex .text,
.org-coaches-ex .text {
  margin-bottom: 1rem;
}
.ath-profile-ex .text h2,
.sport-cred-ex .text h2,
.phys-tests-ex .text h2,
.pitch-ex .text h2,
.ath-search-ex .text h2,
.picks-ex .text h2,
.org-coaches-ex .text h2 {
  margin: 2rem 0 1rem 0;
  font-size: 1.2rem;
  font-weight: bold;
}
.ath-profile-ex .text h2 {
  margin-bottom: 2rem;
}
.ath-profile-ex .text p,
.sport-cred-ex .text p,
.phys-tests-ex .text p,
.pitch-ex .text p,
.picks-ex .text p {
  font-size: 1rem;
}
.picks-ex .text .text-2 {
  margin-top: 0.5rem;
}
.sport-cred-ex .text .box {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  margin: 1rem 0;
}
/* Membership options slide */
.ath-memb-ex,
.coach-memb-ex,
.org-memb-ex {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.ath-memb-ex .header,
.coach-memb-ex .header,
.org-memb-ex .header {
  margin: 2rem 0 3rem 0;
}
.ath-memb-ex .boxes,
.coach-memb-ex .boxes,
.org-memb-ex .boxes {
  width: 100%;
  margin: 0 10px;
  max-width: 800px;
  height: 40%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 3px;
}
.coach-memb-ex .boxes {
  max-width: 600px;
}
.ath-memb-ex .boxes .box,
.coach-memb-ex .boxes .box,
.org-memb-ex .boxes .box {
  width: auto;
  max-width: 170px;
  margin: 0 5px;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: 1 px solid #fff;
  padding: 0.25rem;
  position: relative;
}
.ath-memb-ex .boxes .box.premium,
.coach-memb-ex .boxes .box.premium,
.org-memb-ex .boxes .box.premium {
  border: 1px solid #d60909;
}
.ath-memb-ex .boxes .box.premium .plan,
.coach-memb-ex .boxes .box.premium .plan,
.org-memb-ex .boxes .box.premium .plan {
  color: #d60909;
}
.ath-memb-ex .boxes .box .plan,
.coach-memb-ex .boxes .box .plan,
.org-memb-ex .boxes .box .plan {
  font-weight: bold;
  flex-wrap: wrap;
  text-align: center;
}
.ath-memb-ex .boxes .box .price,
.coach-memb-ex .boxes .box .price,
.org-memb-ex .boxes .box .price {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  top: 30%;
}
.ath-memb-ex .boxes .box .price .main,
.coach-memb-ex .boxes .box .price .main,
.org-memb-ex .boxes .box .price .main {
  font-size: 3rem;
  line-height: 3rem;
}
.ath-memb-ex .boxes .box .price .decimal,
.coach-memb-ex .boxes .box .price .decimal,
.org-memb-ex .boxes .box .price .decimal {
  font-size: 1rem;
}
.ath-memb-ex .boxes .box .period,
.coach-memb-ex .boxes .box .period,
.org-memb-ex .boxes .box .period {
  position: absolute;
  top: 57%;
}
.ath-memb-ex .boxes .box .button,
.coach-memb-ex .boxes .box .button,
.org-memb-ex .boxes .box .button {
  position: absolute;
  bottom: 20px;
  color: #fff;
  background-color: #d60909;
  padding: 0.2rem 0.5rem;
  border: 1px solid #d60909;
}
.ath-memb-ex .boxes .box .button:hover,
.coach-memb-ex .boxes .box .button:hover,
.org-memb-ex .boxes .box .button:hover {
  cursor: pointer;
  background-color: #fff;
  color: #d60909;
  border: 1px solid #d60909;
}
.ath-memb-ex .membership-container,
.coach-memb-ex .membership-container,
.org-memb-ex .membership-container {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.ath-memb-ex .membership-box,
.coach-memb-ex .membership-box,
.org-memb-ex .membership-box {
  width: 95%;
  max-width: 750px;
  min-height: 450px;
  max-height: 475px;
  background-color: #fff;
  margin-top: 1.5rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  z-index: 8;
  overflow-y: scroll;
}
.ath-memb-ex .membership-box::-webkit-scrollbar,
.coach-memb-ex .membership-box::-webkit-scrollbar,
.org-memb-ex .membership-box::-webkit-scrollbar {
  display: none;
}
.ath-memb-ex .membership-box {
  height: 400px;
}
.ath-memb-ex .membership-box .close,
.coach-memb-ex .membership-box .close,
.org-memb-ex .membership-box .close {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  font-size: 1.2rem;
  color: #d60909;
}
.ath-memb-ex .membership-box .close:hover,
.coach-memb-ex .membership-box .close:hover,
.org-memb-ex .membership-box .close:hover {
  cursor: pointer;
}
.ath-memb-ex .membership-box .plan,
.coach-memb-ex .membership-box .plan,
.org-memb-ex .membership-box .plan {
  margin: 1rem 0 2rem 0;
  color: #d60909;
}
.ath-memb-ex .membership-box .feature-list,
.coach-memb-ex .membership-box .feature-list,
.org-memb-ex .membership-box .feature-list {
  width: 100%;
  max-width: 530px;
}
.ath-memb-ex .membership-box .feature-list li,
.coach-memb-ex .membership-box .feature-list li,
.org-memb-ex .membership-box .feature-list li {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0.25rem 0;
}
.ath-memb-ex .membership-box .feature-list li i,
.coach-memb-ex .membership-box .feature-list li i,
.org-memb-ex .membership-box .feature-list li i {
  color: #28a745;
  padding-left: 7px;
}
.ath-memb-ex .membership-box .feature-list ul li .text,
.coach-memb-ex .membership-box .feature-list ul li .text,
.org-memb-ex .membership-box .feature-list ul li .text {
  font-weight: bolder;
  text-align: start;
}
.org-memb-ex .membership-box .feature-list ul li .text .standout {
  color: #d60909;
}
.ath-memb-ex .membership-box .feature-list ul li .text.false,
.coach-memb-ex .membership-box .feature-list ul li .text.false,
.org-memb-ex .membership-box .feature-list ul li .text.false {
  color: #9b9696;
  font-weight: normal;
}
.ath-memb-ex .membership-box .feature-list ul li .check-true,
.coach-memb-ex .membership-box .feature-list ul li .check-true,
.org-memb-ex .membership-box .feature-list ul li .check-true {
  color: #28a745;
  margin-left: 1rem;
}
.ath-memb-ex .membership-box .feature-list ul li .check-false,
.coach-memb-ex .membership-box .feature-list ul li .check-false,
.org-memb-ex .membership-box .feature-list ul li .check-false {
  color: #d60909;
  margin-left: 1rem;
  margin-right: 0.2rem;
}
.ath-memb-ex .membership-box .price,
.coach-memb-ex .membership-box .price,
.org-memb-ex .membership-box .price {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  margin: 2rem 0 0.5rem 0;
}
.ath-memb-ex .membership-box .price .main,
.coach-memb-ex .membership-box .price .main,
.org-memb-ex .membership-box .price .main {
  font-size: 2.5rem;
  line-height: 2.5rem;
  margin-right: 3px;
}
.ath-profile-ex .img img,
.sport-cred-ex .img img,
.phys-tests-ex .img img,
.pitch-ex .img img,
.coach-prof-ex .img img,
.ath-search-ex .img img,
.groups-ex .img img,
.picks-ex .img img,
.org-prof-ex .img img,
.org-coaches-ex .img img,
.org-notice-ex .img img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.landing-contact {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 0.5rem 1rem 0.5rem;
  background-color: #242424;
  border-top: 3px solid #d60909;
}
.landing-contact .contact-info {
  min-width: 350px;
  max-width: 767px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  color: #fff;
  padding: 0 1rem;
}
.landing-contact .email {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 0.5rem;
}
.landing-contact .instagram {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.landing-contact .email i,
.landing-contact .instagram i {
  margin-right: 0.5rem;
  color: #d60909;
  font-size: 1.2rem;
}
.landing-contact .legal {
  min-width: 340px;
  width: auto;
  max-width: 767px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  color: #d60909;
  margin-top: 1.5rem;
  font-size: 0.8rem;
}
.landing-contact .legal a {
  text-decoration: none;
}
.landing-contact .legal .divider {
  margin: 0 0.5rem;
}
.landing-contact .reserved {
  color: #d60909;
  display: flex;
  align-items: center;
  justify-content: center;
  
}
.landing-contact .reserved .year {
  margin: 0 0.3rem;
}
.landing-container .ad-banner {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  background-color: #28a745;
  color: #fff;
  padding: 5px 10px;
  margin-top: 3rem;
}
.landing-container .ad-banner .bold {
  font-weight: bold;
} 
.landing-container .ad-banner .small-text {
  font-size: 0.6rem;
}
/* Media Queries */
/* Small devices (portrait tablets and large phones, 608px and up) */
@media only screen and (min-width: 608px) {
  .landing-1-inner {
    max-width: 575px;
    font-size: 1.1rem;
  }
  .phys-tests-ex,
  .pitch-ex,
  .ath-search-ex,
  .picks-ex {
    width: 120%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    padding: 0;
  }
    .org-coaches-ex {
      width: 110%;
      height: 100%;
        display: grid;
        grid-template-columns: 1fr 2fr 1fr;
        padding: 0;
    }
  .ath-search-ex .img.left,
  .phys-tests-ex .img.left,
  .pitch-ex .img.left,
  .picks-ex .img.left,
  .org-coaches-ex .img.left {
    grid-column: 1 / 2;
    grid-row: 1;
  }
  .ath-search-ex .img.right,
  .phys-tests-ex .img.right,
  .pitch-ex .img.right,
  .picks-ex .img.right,
  .org-coaches-ex .img.right {
    grid-column: 3 / 4;
    grid-row: 1;
  }
  .ath-profile-ex .text h2,
  .sport-cred-ex .text h2,
  .phys-tests-ex .text h2,
  .pitch-ex .text h2,
  .ath-memb-ex .header,
  .ath-search-ex .text h2,
  .org-coaches-ex .text h2,
  .picks-ex .text h2,
  .coach-memb-ex .header,
  .org-memb-ex .header {
    font-size: 1.3rem;
    margin: 3rem 0;
  }
  .org-prof-ex .text h2 {
    font-size: 1.3rem;
  }
  .coach-prof-ex .text,
  .groups-ex .text,
  .org-notice-ex .text {
    width: 70%;
    margin: 3rem 0 1.5rem 0;
  }
  .phys-tests-ex .text,
  .pitch-ex .text,
  .ath-search-ex .text,
  .org-coaches-ex .text,
  .picks-ex .text {
    width: 100%;
    max-width: 340px;
    grid-column: 2 / 3;
    justify-self: center;
  }

  .coach-prof-ex .text h2,
  .groups-ex .text h2,
  .org-notice-ex .text h2,
  .ath-memb-ex .membership-box h2,
  .coach-memb-ex .membership-box h2,
  .org-memb-ex .membership-box h2 {
    font-size: 1.3rem;
  }

  .ath-profile-ex .text p,
  .sport-cred-ex .text p,
  .phys-tests-ex .text p,
  .pitch-ex .text p,
  .ath-search-ex .text p,
  .coach-prof-ex .text p,
  .groups-ex .text p,
  .org-notice-ex .text p,
  .picks-ex .text p,
  .org-coaches-ex .text p,
    .org-prof-ex .text p {
    font-size: 1.1rem;
  }
  .picks-ex .text .text-2 {
    margin-top: 1rem;
  }
  .ath-profile-ex .img,
  .sport-cred-ex .img,
  .phys-tests-ex .img,
  .pitch-ex .img,
  .ath-search-ex .img,
  .picks-ex .img,
  .org-coaches-ex .img {
    height: 350px;
    width: 230px;
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .coach-prof-ex .img, .org-prof-ex .img {
    width: 420px;
    height: 280px;
  }
  .groups-ex .img,
  .org-notice-ex .img {
    width: 480px;
    height: 280px;
  }
  .ath-memb-ex .boxes .box,
  .coach-memb-ex .boxes .box,
  .org-memb-ex .boxes .box {
    width: auto;
    max-width: 190px;
    min-width: 150px;
  }
  .ath-memb-ex .membership-box .price .main,
  .coach-memb-ex .membership-box .price .main,
  .org-memb-ex .membership-box .price .main {
    font-size: 3rem;
    line-height: 3rem;
    margin-right: 3px;
  }
  .ath-memb-ex .membership-box .price,
  .coach-memb-ex .membership-box .price,
  .org-memb-ex .membership-box .price {
    margin: 2rem 0 1rem 0;
  }
  .landing-contact .contact-info, .landing-contact .legal {
    min-width: 500px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .landing-1 {
    height: 500px;
  }
  .landing-1 .main-header h1 {
    font-size: 2rem;
  }
  .landing-1-inner {
    max-width: 600px;
    align-self: center;
    font-size: 1.2rem;
  }
  .landing-1-inner .img img {
    width: 160px;
    height: 310px;
  }
  .landing-2,
  .landing-2 .light-overlay {
    height: 640px;
  }
  .landing-2-inner .btn,
  .landing-3-inner .btn,
  .landing-4-inner .btn {
    bottom: 80px;
  }
  .landing-3,
  .landing-3 .light-overlay,
  .landing-4, .landing-4 .light-overlay {
    height: 660px;
  }
  .landing-contact {
    justify-content: flex-end;
    font-size: 1.2rem;
  }
  .ath-profile-ex .text,
  .sport-cred-ex .text,
  .phys-tests-ex .text,
  .pitch-ex .text,
  .ath-search-ex .text,
  .picks-ex .text,
  .org-coaches-ex .text {
    margin-top: 4rem;
  }
  .coach-prof-ex .text,
  .groups-ex .text,
  .org-notice-ex .text,
     .org-prof-ex .text {
    max-width: 600px;
  }
  .ath-profile-ex .text h2,
  .sport-cred-ex .text h2,
  .phys-tests-ex .text h2,
  .pitch-ex .text h2,
  .ath-memb-ex h2,
  .ath-search-ex .text h2,
  .org-coaches-ex .text h2,
  .picks-ex .text h2,
  .coach-memb-ex h2,
  .org-memb-ex .header,
    .org-prof-ex .text h2 {
    font-size: 1.5rem;
  }
  .coach-prof-ex .text h2,
  .groups-ex .text h2,
  .org-notice-ex .text h2,
  .ath-memb-ex .membership-box h2,
  .coach-memb-ex .membership-box h2,
  .org-memb-ex .membership-box h2 {
    font-size: 1.5rem;
  }
  .ath-profile-ex .text p,
  .sport-cred-ex .text p,
  .phys-tests-ex .text p,
  .pitch-ex .text p,
  .ath-search-ex .text p,
  .org-coaches-ex .text p,
  .coach-prof-ex .text p,
  .groups-ex .text p,
  .org-notice-ex .text p,
  .picks-ex .text p,
    .org-prof-ex .text p {
    font-size: 1.2rem;
  }
  .ath-profile-ex .img,
  .sport-cred-ex .img,
  .phys-tests-ex .img,
  .pitch-ex .img,
  .ath-search-ex .img,
  .org-coaches-ex .img,
  .picks-ex .img {
    height: 380px;
    width: 270px;
    margin-top: 5rem;
  }
  .phys-tests-ex .img.left,
  .pitch-ex .img.left,
  .ath-search-ex .img.left,
  .org-coaches-ex .img.left,
  .picks-ex .img.left {
    justify-self: flex-start;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .phys-tests-ex .img.right,
  .pitch-ex .img.right,
  .ath-search-ex .img.right,
  .org-coaches-ex .img.right,
  .picks-ex .img.right {
    justify-self: flex-end;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .coach-prof-ex .img, .org-prof-ex .img {
    width: 400px;
    height: 300px;
  }
  .groups-ex .img,
  .org-notice-ex .img {
    height: 320px;
    width: 620px;
    margin-top: 2rem;
  }
  .sport-cred-ex {
    width: 100%;
  }
  .sport-cred-ex .text {
    text-align: left;
    align-items: flex-start;
  }
  .phys-tests-ex,
  .pitch-ex,
  .ath-search-ex,
  .picks-ex,
  .org-coaches-ex {
    width: 100%;
  }
  .ath-memb-ex .header,
  .coach-memb-ex .header,
  .org-memb-ex .header {
    margin: 3rem 0 5rem 0;
  }
  .ath-memb-ex .membership-box {
    height: 450px;
  }
  .coach-memb-ex .membership-box,
  .org-memb-ex .membership-box {
    height: 500px;
    max-height: 500px;
  }
  .ath-memb-ex .membership-box p,
  .ath-memb-ex .membership-box i,
  .ath-memb-ex .membership-box .term,
  .ath-memb-ex .membership-box .close,
  .coach-memb-ex .membership-box p,
  .coach-memb-ex .membership-box i,
  .coach-memb-ex .membership-box .term,
  .coach-memb-ex .membership-box .close,
  .org-memb-ex .membership-box p,
  .org-memb-ex .membership-box i,
  .org-memb-ex .membership-box .term,
  .org-memb-ex .membership-box .close {
    font-size: 1.2rem;
  }
  .ath-memb-ex .boxes .box,
  .coach-memb-ex .boxes .box,
  .org-memb-ex .boxes .box {
    width: auto;
    max-width: 200px;
    min-width: 170px;
  }
    .landing-contact .contact-info,
    .landing-contact .legal {
      min-width: 600px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .landing-container {
    top: 75px;
  }
  .landing-1 .main-header h1 {
    font-size: 2.5rem;
  }
  .landing-1 {
    height: 550px;
  }
  .landing-1-inner {
    max-width: 900px;
    align-self: center;
    font-size: 1.5rem;
  }
  .landing-1-inner .img img {
    height: 350px;
    width: 180px;
  }
  .landing-2-inner .btn,
  .landing-3-inner .btn,
  .landing-4-inner .btn {
    padding: 0.2rem 1rem;
    font-size: 1.1rem;
  }
  .landing-2-header,
  .landing-3-header,
  .landing-4-header {
    font-size: 2rem;
    padding: 1.7rem;
  }
  .ath-profile-ex .img {
    height: 400px;
    margin-right: 3rem;
  }
  .ath-profile-ex,
  .sport-cred-ex {
    max-width: 900px;
  }
  .phys-tests-ex,
  .pitch-ex,
  .ath-search-ex,
  .picks-ex,
  .org-coaches-ex {
    max-width: 950px;
  }
  .sport-cred-ex .img {
    height: 400px;
    justify-self: center;
  }
  .ath-profile-ex .text {
    max-width: 400px;
  }
  .sport-cred-ex .text {
    max-width: 450px;
  }
  .ath-profile-ex .text h2,
  .sport-cred-ex .text h2,
  .phys-tests-ex .text h2,
  .pitch-ex .text h2,
  .ath-memb-ex .header,
  .ath-search-ex .text h2,
  .org-coaches-ex .text h2,
  .picks-ex .text h2,
  .coach-memb-ex .header,
  .org-memb-ex .header,
  .coach-prof-ex .text h2,
  .groups-ex .text h2,
  .org-notice-ex .text h2,
    .org-prof-ex .text h2 {
    font-size: 2rem;
  }
  .ath-profile-ex .text p,
  .sport-cred-ex .text p,
  .phys-tests-ex .text p,
  .pitch-ex .text p,
  .ath-memb-ex .text p,
  .ath-search-ex .text p,
  .org-coaches-ex .text p,
  .picks-ex .text p,
  .coach-memb-ex .text p,
  .org-memb-ex .text p,
  .coach-prof-ex .text p,
  .groups-ex .text p,
  .org-notice-ex .text p,
    .org-prof-ex .text p {
    font-size: 1.5rem;
  }
  .coach-prof-ex,
  .groups-ex,
  .org-notice-ex,
    .org-prof-ex {
    max-width: 850px;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: flex-start;
  }
  .coach-prof-ex .text, .org-prof-ex .text {
    width: auto;
    max-width: 400px;
    justify-self: center;
    margin-top: 25%;
  }
  .coach-prof-ex .text h2,
  .groups-ex .text h2,
  .org-notice-ex .text h2,
    .org-prof-ex .text h2 {
    margin: 3rem 0;
  }
  .coach-prof-ex .img,
    .org-prof-ex .img
   {
    justify-self: flex-end;
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 35%;
  }
  .groups-ex .text,
  .org-notice-ex .text {
    grid-column: 1 / 3;
    grid-row: 1;
    width: auto;
    max-width: 400px;
    justify-self: flex-end;
    margin-top: 14%;
  }
  .groups-ex .text h2,
  .org-notice-ex .text h2 {
    margin-bottom: 1rem;
  }
  .groups-ex .img,
  .org-notice-ex .img {
    grid-column: 1 / 2;
    grid-row: 1;
    margin-top: 23%;
    justify-self: flex-start;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
    .groups-ex .img {
      margin-top: 28%;
    }
  .swiper .swiper-button-prev,
  .swiper .swiper-button-next {
    color: #9b9696;
    display: block;
    margin: 0 3rem;
  }
}
