.error-container {
  position: fixed;
  background-color: #fff;
  width: 100%;
  height: 100vh;
  top: 66px;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.error-container .error-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.error-container .error-content .error {
  font-size: 6rem;
  margin: 3rem 0;
  color: #d60909;
}
.error-container .error-content .error-message {
  color: #242424;
  font-weight: bold;
}
.error-container .error-content .reload-button {
  display: inline-block;
  background-color: #d60909;
  color: #fff;
  padding: 0.1rem 1rem;
  border-radius: 15px;
  cursor: pointer;
  font-size: 1rem;
  border: 1px solid #d60909;
  text-align: center;
  margin: 3rem;
}
