/* Extra small devices (phones, 608px and down) */

#root {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 15px;
  background-color: #fff;
  z-index: 9;
  /* Define variables */
  --primary-color: #d60909;
}
html {
  font-size: 15px;
}
.shell-container {
  font-size: 15px;
}
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  line-height: 1.5;
  background-color: #fff;
  color: #242424;
}
.m-1 {
  margin: 1rem;
}
.my-1 {
  margin: 1rem 0;
}
.p-1 {
  padding: 1rem;
}
.py-1 {
  padding: 1rem 0;
}
.m-2 {
  margin: 2rem;
}
.my-2 {
  margin: 2rem 0;
}
.p-2 {
  padding: 2rem;
}
.py-2 {
  padding: 2rem 0;
}
.m-3 {
  margin: 3rem;
}
.my-3 {
  margin: 3rem 0;
}
.p-3 {
  padding: 3rem;
}
.py-3 {
  padding: 3rem 0;
}
.m-4 {
  margin: 4rem;
}
.my-4 {
  margin: 4rem 0;
}
.p-4 {
  padding: 4rem;
}
.py-4 {
  padding: 4rem 0;
}
.m-5 {
  margin: 5rem;
}
.my-5 {
  margin: 5rem 0;
}
.p-5 {
  padding: 5rem;
}
.py-5 {
  padding: 5rem 0;
}
h2 {
  font-size: 1.2rem;
}
h4 {
  font-size: 1rem;
}
h5 {
  font-size: 0.9rem;
}
.large {
  font-size: 2rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}
.lead {
  text-align: center;
  flex-wrap: wrap;
  font-size: 1.2rem;
  padding: 1rem;
}
.lead i {
  margin-right: 0.5rem;
}
.text-primary {
  color: #d60909;
}
.page-header {
  flex-wrap: wrap;
  text-align: center;
  color: #d60909;
  margin-bottom: 3rem;
}
.bg-primary {
  background-color: #d60909;
  color: #fff;
}
.bg-light {
  background-color: #fff;
  color: #d60909;
  border: #ccc 1px solid;
}
.bg-dark {
  background-color: #242424;
  color: #fff;
}
.bg-success {
  background-color: #28a745;
  color: #fff;
}
.bg-danger {
  background-color: #dc3545;
  color: #fff;
}
.bg-white {
  background-color: #fff;
  color: #d60909;
  border: #ccc 1px solid;
}
.light-overlay {
  height: 600px;
  width: 100%;
  background-color: rgba(250, 249, 249, 0.9);
}
.dark-overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
}
.btn {
  display: inline-block;
  background-color: #d60909;
  color: #fff;
  padding: 0.1rem 1rem;
  border-radius: 15px;
  cursor: pointer;
  font-size: 1rem;
  border: 1px solid #d60909;
}
.btn.btn-primary {
  background-color: #d60909;
  color: #fff;
  border: 1px solid #d60909;
  text-align: center;
}
.btn.btn-primary:hover {
  background-color: #fff;
  color: #d60909;
  border: 1px solid #d60909;
  text-align: center;
}
.btn.btn-primary.small {
  border-radius: 10px;
  border: 1px solid #d60909;
}
.btn.btn-light {
  background-color: #fff;
  color: #d60909;
  border: 1px solid #d60909;
  text-align: center;
}
.btn.btn-light:hover {
  background-color: #d60909;
  color: #fff;
}
.btn.btn-light.small {
  border-radius: 10px;
}

.btn.btn-grey {
  background-color: #9b9696;
  color: #fff;
  border: 1px solid #9b9696;
}
.btn.btn-grey:hover {
  background-color: #fff;
  color: #d60909;
  border: 1px solid #d60909;
}
.btn.btn-grey.small {
  border-radius: 10px;
}
.btn-square {
  border-radius: 0;
  width: 50px;
  height: 30px;
  padding-left: 0.6rem;
}
.btn.btn-selected {
  background-color: #fff;
  color: #d60909;
  border: 1px solid #d60909;
  font-weight: bolder;
}
.btn.btn-unselected {
  background-color: #f5f3f3;
  color: #d60909;
  color: #d60909;
  border: 1px solid #f5f3f3;
}
.btn.btn-unselected:hover {
  background-color: #fff;
  color: #d60909;
  border: 1px solid #d60909;
}
input {
  font-size: 16px !important;
}
.alert {
  background: #f5f3f3;
  padding: 0.8rem;
  margin: auto;
  color: #242424;
  width: 80%;
  position: fixed;
  bottom: 1.5rem;
  left: 10%;
  right: 10%;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.alert.alert-primary {
  background-color: #d60909;
  color: #fff;
}
.alert.alert-dark {
  background-color: #242424;
  color: #fff;
}
.alert.alert-danger {
  background-color: #dc3545;
  color: #fff;
}
.alert.alert-success {
  background-color: #28a745;
  color: #fff;
}
.box-shadow {
  -webkit-box-shadow: 0 0px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.2);
}
.custom-file-input {
  display: none;
}
.custom-file-label {
  width: 100%;
}

/* Main Container */
.container {
  max-width: 1000px;
  min-height: 100vh;
  height: auto;
  margin: auto;
  overflow: hidden;
  padding: 0.7rem;
  margin-top: 66px;
  /* margin-bottom: 3rem; */
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

a {
  text-decoration: none;
  color: #d60909;
}

/* Loading Spinner */
.loading-container {
  position: absolute;
  top: 0;
  left: 1%;
  width: 98%;
  height: 100vh;
  background-color: white;
  opacity: 0.95;
}
.video-loading-container {
  height: 100%;
}
.loading {
  position: absolute;
  top: 20%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.loading .text {
  margin-top: 1.5rem;
  color: #d60909;
  font-weight: bolder;
}

/* Full page loading spinner */
.main-spinner-container {
  position: fixed;
  top: 66px;
  width: 100%;
  height: 100vh;
  background-color: white;
  opacity: 0.8;
  overflow-y: hidden;
}
.loading-main {
  position: fixed;
  top: 66px;
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center;
  z-index: 900;
}
.loading-main .loading-spinner {
  max-width: 15rem;
  height: auto;
}
.loading-main .text {
  margin-top: 1.5rem;
  color: #d60909;
  font-weight: bolder;
}
.loading-dots {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
  margin: 0.5rem;
}

/* Forms */
.form {
  position: relative;
  margin-top: 0.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  opacity: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.form-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  grid-column: span 2;
  justify-self: center;
  width: 95%;
  max-width: 900px;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  padding: 1.5rem 0.5rem;
  margin-bottom: 1.5rem;
}
.form-box .form-category,
.test-form-box .form-category {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #9b9696;
}
.form-box .form-category:hover {
  color: #7d7777;
  cursor: pointer;
}
.form-box .form-category i {
  font-size: 2rem;
  margin-left: 1rem;
}
.form-box .form-category .header {
  margin-left: 1.5rem;
}
.form-box .form-category.completed {
  color: #d60909;
}
.form-group {
  margin: 0.5rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}
.form-group.organisation-from-link {
  display: block;
  width: 100%;
  padding: 0.4rem 1rem;
  font-size: 1.2rem;
  border: solid 1px #d60909;
  border-radius: 5px;
  font: inherit;
  font-weight: bold;
}
.form-group .incorrect {
  position: absolute;
  bottom: -30px;
  z-index: 5;
  background-color: #242424;
  color: #fff;
  padding: 1px 4px;
  white-space: nowrap;
}
.units {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.form-text {
  display: block;
  margin-top: 0.3rem;
  color: #888;
}
.form input[type="text"],
.form input[type="email"],
.form input[type="password"],
.form input[type="date"],
.form input[type="textarea"],
.form select,
.form textarea,
.form option {
  display: block;
  width: 100%;
  padding: 0.4rem 1rem;
  font-size: 1.2rem;
  border: solid 1px #d60909;
  border-radius: 5px;
  font: inherit;
  margin: 0.05rem 0;
}
.form input[type="text"].small,
.form input[type="email"].small,
.form input[type="password"].small,
.form input[type="date"].small,
.form select.small,
.form textarea.small,
.form option.small {
  width: 80%;
}
.form input[type="submit"] {
  font: inherit;
  -ms-grid-column-align: center;
  justify-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  text-align: center;
  margin-top: 1rem;
  -webkit-appearance: none;
}
.form input[type="button"]:focus {
  border-radius: 0;
}
.form ::-webkit-input-placeholder {
  color: #242424;
  opacity: 0.6;
}
.form :-ms-input-placeholder {
  color: #242424;
  opacity: 0.6;
}
.form ::-ms-input-placeholder {
  color: #242424;
  opacity: 0.6;
}
.form ::placeholder {
  color: #242424;
  opacity: 0.6;
}
.form input:focus {
  outline: none;
  border: 2px solid #d60909;
  border-radius: 5px;
}
.form input:focus::placeholder {
  color: transparent;
}
.form textarea {
  resize: none;
}
.form textarea::-webkit-scrollbar {
  background-color: #f5f3f3;
  width: 5px;
  border-radius: 0 5px 5px 0;
}
.form textarea::-webkit-scrollbar-thumb {
  background: #d60909;
  width: 2px;
  min-height: 50px;
  border-radius: 20px;
}
.form textarea:focus {
  outline: none !important;
  border: 2px solid #d60909;
}
.dateInput {
  background-color: #fff;
  max-width: 50%;
}
.birthdate {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.birthdate p {
  padding-left: 1rem;
}
.dropdown-container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  margin-top: 1rem;
}
.dropdown-container .selected {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.4rem 1rem;
  margin-bottom: 0.5rem;
  font-size: 16px;
  border: solid 1px #d60909;
  border-radius: 5px;
  max-height: 39.78px;
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
}
.dropdown-container .selected:hover {
  cursor: pointer;
}
.dropdown-container .disabled-selected {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.4rem 1rem;
  margin-bottom: 0.5rem;
  font-size: 16px;
  color: #9b9696;
  border: solid 1px #d60909;
  border-radius: 5px;
  max-height: 39.78px;
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
}
.dropdown-container .selected .selected-text {
  width: 80%;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
  color: #242424;
}
.dropdown-container .selected .selected-text .placeholder {
  color: #9b9696;
}
.dropdown-container .selected .selected-text.placeholder {
  color: #9b9696;
}
.dropdown-container .selected .selected-text p {
  max-width: calc(100%);
  text-overflow: ellipsis;
  overflow: hidden;
}
.dropdown-container .options-container {
  background-color: #fff;
  max-height: 11rem;
  overflow-y: scroll;
  width: 100%;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  border: 1px solid #f5f3f3;
  position: absolute;
  top: 37.82px;
  z-index: 3;
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 1;
  order: 1;
}
.dropdown-container .options-container::-webkit-scrollbar,
.register-form .organisation-suggested::-webkit-scrollbar {
  background-color: #f5f3f3;
  width: 5px;
  border-radius: 0 5px 5px 0;
}
.dropdown-container .options-container::-webkit-scrollbar-thumb,
.register-form .organisation-suggested::-webkit-scrollbar-thumb {
  background: #d60909;
  width: 2px;
  min-height: 50px;
  border-radius: 20px;
}
.dropdown-container .options-container.active {
  max-height: 11rem;
  overflow-y: scroll;
  opacity: 1;
}
.dropdown-container .options-container.sports-options {
  min-height: 8rem;
}
.dropdown-container .options-container .option,
.dropdown-container .options-container .sport-option {
  background-color: #fff;
  padding: 0.5rem;
  width: 100%;
}
.dropdown-container .options-container .option:hover,
.dropdown-container .options-container .sport-option:hover {
  background-color: #f5f3f3;
  cursor: pointer;
}
.dropdown-container .options-container .option:hover,
.dropdown-container .options-container .empty {
  padding: 0.5rem;
}
.dropdown-container .options-container .option label {
  width: 100%;
  display: inline-block;
  text-align: start;
}
.dropdown-container .options-container .option label:hover {
  cursor: pointer;
}
.dropdown-container .options-container .option .radio {
  display: none;
}
.dropdown-container .options-container .searchbar {
  border-bottom: 1px solid #f5f3f3;
  width: 100%;
}
.dropdown-container .options-container .searchbar input[type="text"] {
  border: none;
  padding: 0.5rem;
}
.dropdown-container .options-container .searchbar input[type="text"]:focus {
  font-style: normal;
}
.dropdown-container
  .options-container
  .searchbar
  input[type="text"]::-webkit-input-placeholder {
  color: #9b9696;
}
.dropdown-container .options-container .athletics-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;
  padding: 0.5rem;
  color: #d60909;
}
.dropdown-container .options-container .athletics-header:hover {
  cursor: pointer;
}
.dropdown-container .options-container .athletics-header .back {
  margin: 0 0.5rem;
}
.dropdown-container .options-container .athletics-header .text {
  width: 100%;
  text-align: center;
}
.dropdown-container .options-container .athletics-option {
  background-color: #fff;
  padding: 0.5rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.dropdown-container .options-container .athletics-option:hover {
  background-color: #f5f3f3;
  cursor: pointer;
}
.dropdown-container .options-container .athletics-option .arrow {
  color: #d60909;
}
/* Flag Select */
.ReactFlagsSelect-module_flagsSelect__2pfa2 {
  margin-top: 0.5rem;
}
.ReactFlagsSelect-module_flagsSelect__2pfa2
  .ReactFlagsSelect-module_selectBtn__19wW7 {
  border: 1px solid #d60909;
  border-radius: 5px;
  background-color: #fff;

  &::after {
    border: none !important;
    content: "\f078" !important;
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    height: unset;
    width: unset;
    position: relative;
    left: -4px;
  }
}
.ReactFlagsSelect-module_selectBtn__19wW7[aria-expanded="true"]:after {
  content: "\f077" !important;
  border: none !important;
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  height: unset !important;
  width: unset !important;
  position: relative;
  left: -4px;
}

.ReactFlagsSelect-module_flagsSelect__2pfa2
  .ReactFlagsSelect-module_selectOptions__3LNBJ {
  margin-top: 0;
  border: 1px solid #f5f3f3;
}
.ReactFlagsSelect-module_selectOptions__3LNBJ::-webkit-scrollbar {
  display: none;
}
.ReactFlagsSelect-module_filterBox__3m8EU input[type="text"] {
  border: none;
  -webkit-focus-ring-color: none;
}
.ReactFlagsSelect-module_filterBox__3m8EU
  input[type="text"]::-webkit-input-placeholder {
  color: #9b9696;
}
.ReactFlagsSelect-module_selectOptionsWithSearch__1W03w
  .ReactFlagsSelect-module_filterBox__3m8EU {
  padding: 0;
}
.ReactFlagsSelect-module_selectValue__152eS {
  padding: 0 !important;
  color: #9b9696 !important;
  .ReactFlagsSelect-module_label__27pw9 {
    color: #242424 !important;
  }
}

/* Login/Register */
.register-container,
.login-container,
.request-reset-container {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 3rem;
}
.register-container .expired-link {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.register-container .expired-link i {
  font-size: 7rem;
  margin: 2rem 0 4rem 0;
  color: #d60909;
}
.register-container .expired-link .text {
  text-align: center;
}
.register-container .expired-link .buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 3rem 0;
}
.register-container .expired-link .buttons .btn {
  margin: 0 0.5rem;
  width: 7rem;
}
.request-reset-back {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #d60909;
}
.request-reset-back i {
  margin-right: 0.5rem;
}
.login-container .forgotten {
  align-self: center;
  margin: 0.5rem;
}
.login-container .btn.login {
  margin-top: 1.5rem;
}
.request-reset-container .btn.send,
.request-reset-container .btn.submit,
.request-reset-container .btn.login {
  margin-top: 3rem;
}
.request-reset-container .email-sent,
.request-reset-container .password-updated {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.request-reset-container .email-sent i,
.request-reset-container .password-updated i {
  color: #28a745;
  font-size: 5rem;
  margin: 1rem;
}
.lead.subheader {
  margin-bottom: 2rem;
}

/* Register */
.reg-type {
  display: flex;
  justify-content: space-between;
  line-height: 1.1rem;
}
.reg-type .btn {
  border-radius: 0;
  margin: 0 0.5rem;
  display: flex;
  text-align: center;
  padding: 0.5rem 1rem;
}
.register-form {
  width: 100%;
}
.register-form .password-form-group .confirm {
  margin-top: 1rem;
}
.register-form .caregiver-email-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1rem;
}
.register-form .caregiver-email-section .form-group {
  width: 100%;
}
.register-form .caregiver-email-section .prompt {
  width: 100%;
  font-style: italic;
  color: #d60909;
  align-self: flex-start;
}
.register-form .caregiver-email-section .prompt-small {
  font-size: 0.8rem;
  color: #d60909;
  align-self: flex-start;
}
.register-form .select-gender {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 1rem;
}
.register-form .select-gender .prompt {
  color: #242424;
}
.register-form .select-gender .option,
.register-form .coach-options .coach-option,
.register-form .options-container .sport-option {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.register-form .select-gender .option input[type="radio"],
.register-form .coach-options .coach-option input[type="radio"],
.register-form .options-container .sport-option input[type="checkbox"] {
  cursor: pointer;
  opacity: 0;
  position: absolute;
}
.register-form .select-gender .option label,
.register-form .coach-options .coach-option label,
.register-form .options-container .sport-option label {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #9b9696;
}
.register-form .select-gender .option label::before,
.register-form .coach-options .coach-option label::before,
.register-form .options-container .sport-option label::before {
  content: "";
  width: 1rem;
  height: 1rem;
  border: 1px solid #9b9696;
  border-radius: 50%;
  margin-right: 0.5rem;
}
.register-form .select-gender .option label:hover::before,
input[type="radio"]:hover + label::before,
.register-form .coach-options .coach-option label:hover::before,
.register-form .options-container .sport-option label:hover::before {
  border: 1px solid #d60909;
}
.register-form .select-gender .option input[type="radio"]:focus + label::before,
.register-form
  .coach-options
  .coach-option
  input[type="radio"]:focus
  + label::before,
.register-form
  .options-container
  .sport-option
  input[type="checkbox"]:focus
  + label::before {
  border: 1px solid #d60909;
}
.register-form
  .select-gender
  .option
  input[type="radio"]:checked
  + label::before,
.register-form
  .coach-options
  .coach-option
  input[type="radio"]:checked
  + label::before,
.register-form
  .options-container
  .sport-option
  input[type="checkbox"]:checked
  + label::before {
  content: "\f00c";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  font-size: 0.7rem;
  color: #d60909;
  border: 1px solid #d60909;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.register-form .select-gender .option input[type="radio"]:checked + label,
.register-form
  .coach-options
  .coach-option
  input[type="radio"]:checked
  + label {
  color: #d60909;
  font-weight: bold;
}
.register-form
  .options-container
  .sport-option
  input[type="checkbox"]:checked
  + label {
  color: #d60909;
}
.register-form .password-prompt {
  color: #d60909;
  margin-top: 3rem;
}
.register-form .coach-options {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 2rem;
}
.register-form .coach-options .coach-option {
  margin: 0.5rem 0;
  padding-left: 10%;
}
.register-form .coach-options .coach-option label,
.register-form .options-container .sport-option label {
  color: #242424;
}
.register-form .sports-prompt {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  color: #d60909;
  font-style: italic;
}
.register-form .org-group {
  position: relative;
}
.register-form .organisation-suggested {
  background-color: #fff;
  max-height: 11rem;
  overflow-y: scroll;
  width: 98%;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  border: 1px solid #f5f3f3;
  position: absolute;
  top: 40px;
  left: 1%;
  z-index: 2;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  font-size: 0.9rem;
}
.register-form .organisation-suggested .prompt {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #d60909;
  font-style: italic;
  margin: 0.5rem 0;
}
.register-form .organisation-suggested .org-box {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 3fr 3fr;
  background-color: #fff;
  padding: 0.5rem;
  width: 100%;
}
.register-form .organisation-suggested .org-box:hover {
  background-color: #f5f3f3;
  cursor: pointer;
}
.register-form .organisation-suggested .org-box .image {
  width: 30px;
  height: 30px;
  grid-column: 1 / 2;
  grid-row: span 2;
  justify-self: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.register-form .organisation-suggested .org-box .image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.register-form .organisation-suggested .org-box .organisation-name {
  grid-column: 2 / 3;
  grid-row: 1;
  font-weight: bold;
}
.register-form .organisation-suggested .org-box .city-state {
  grid-column: 2 / 3;
  grid-row: 2;
}
.register-form .organisation-suggested .org-box .country {
  grid-column: 3 / 4;
  grid-row: span 2;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-right: 1rem;
}
.register-form .organisation-suggested .org-box .country .flag {
  font-size: 1.5rem;
}
/* organisation register */
.register-form .coach-details-header,
.register-form .org-details-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0 0.5rem 0;
  font-weight: bold;
}
.register-form .button-disabled {
  align-self: center;
  width: auto;
  background-color: #9b9696;
  color: #fff;
  padding: 0.1rem 1rem;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1rem;
  border: 1px solid #9b9696;
  margin-top: 1rem;
}
.register-form .button-disabled:hover {
  cursor: not-allowed;
}
.invite-expired {
  position: fixed;
  bottom: 2rem;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #dc3545;
  color: #fff;
  padding: 0.5rem;
  text-align: center;
}
.invite-expired .text-1 {
  font-weight: bold;
}
.invite-expired .text-2 a {
  font-style: italic;
  color: #fff;
  text-decoration: underline;
}

/* Modals */
.modal {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
}
.modal-background {
  position: fixed;
  background: black;
  opacity: 0.7;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 9;
}
.modal-container {
  position: fixed;
  top: 66px;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  z-index: 10;
  width: 100%;
  height: 100vh;
}
.modal-container .modal-box {
  min-height: 300px;
  max-height: 75vh;
  top: 5%;
  height: auto;
  width: 95%;
  max-width: 580px;
  border: 2px solid #d60909;
  border-radius: 10px;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow-y: scroll;
  position: relative;
}
.modal-container .modal-box::-webkit-scrollbar,
.modal-container .modal-box .modal-content::-webkit-scrollbar {
  display: none;
}
.modal-content {
  width: 100%;
  padding: 0.5rem;
  max-height: 80vh;
  width: 100%;
  top: 0;
  align-self: flex-start;
  justify-self: center;
  display: grid;
  grid-template-columns: 0.5fr 2fr 0.5fr;
  position: relative;
  overflow-y: scroll;
}

.modal-content .modal-header {
  grid-column: 1 / 4;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;
  margin-top: 1.5rem;
  text-align: center;
}
.modal-content .modal-header.disabled {
  display: none;
}
.modal-content .close {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  font-size: 1.2rem;
  color: #d60909;
}
.modal-content .close:hover {
  cursor: pointer;
  color: #242424;
}
.modal-content .close.disabled {
  display: none;
}
.modal-content .loadComplete,
.test-box-form .loadComplete,
.file-box-form .loadComplete,
.video-form .loadComplete {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-self: center;
  justify-self: center;
  justify-content: center;
  align-items: center;
}
.modal-content .loadComplete .icon,
.test-box-form .loadComplete .icon,
.file-box-form .loadComplete .icon,
.video-form .loadComplete .icon {
  font-size: 6rem;
  color: #28a745;
}
.modal-content .loadComplete .okBtn,
.test-box-form .loadComplete .okBtn,
.file-box-form .loadComplete .okBtn,
.video-form .loadComplete .okBtn {
  margin-top: 2rem;
}
.modal-content .confirm-modal {
  grid-column: span 3;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-self: center;
  justify-self: center;
  justify-content: center;
  align-items: center;
  margin: 4rem auto;
}
.confirm-modal .prompt {
  width: 90%;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
  margin-bottom: 1rem;
}

.confirm-modal .buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
}
.confirm-modal .delete {
  background-color: #d60909;
  color: #fff;
  width: 5rem;
  padding: 0.5rem auto;
  height: auto;
  border: 1px solid #d60909;
  border-radius: 15px;
  text-align: center;
  margin-left: 0.5rem;
}
.confirm-modal .delete:hover {
  cursor: pointer;
  text-decoration: none;
  filter: brightness(110%);
}
.confirm-modal .cancel {
  background-color: #fff;
  color: #d60909;
  width: 5rem;
  padding: 0.5rem auto;
  height: auto;
  border: 1px solid #d60909;
  border-radius: 15px;
  text-align: center;
  margin-right: 0.5rem;
}
.confirm-modal .cancel:hover {
  cursor: pointer;
  background-color: #d60909;
  color: #fff;
  filter: brightness(110%);
}
.sport-modal::-webkit-scrollbar,
.stats-modal::-webkit-scrollbar,
.edit-athlete-account::-webkit-scrollbar {
  display: none;
}
/* Display full size Images */
.fullImgContainer {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
}
.fullImgContainer .img-display {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fullImgContainer img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 90%;
  display: block;
  padding: 80px 0 20px 0;
  margin: 0 auto;
}
.fileImgContainer img {
  max-width: 95%;
}
.fullImgContainer .img-display .close {
  position: absolute;
  color: #d60909;
  top: 100px;
  right: 20px;
  font-size: 1.5rem;
}
.fullImgContainer .img-display .close:hover {
  cursor: pointer;
}
.fullImgContainer .pages {
  position: absolute;
  bottom: 1rem;
  color: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
}
.fullImgContainer .pages .page-numbers {
  margin: 0 2rem;
}
.fullImgContainer .pages .disabled {
  color: #9b9696;
}
.install-prompt-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.install-prompt-container .install-prompt {
  width: 100%;
  max-height: 300px;
  border-top: 2px solid #d60909;
  color: #fff;
  background-color: #242424;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 5rem;
  animation-duration: 500ms;
  animation-name: install;
}
@keyframes install {
  from {
    height: 0;
  }
  to {
    max-height: 300px;
  }
}
.install-prompt-container .install-prompt .prompt-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem 1rem;
}
.install-prompt-container .install-prompt .prompt-content .title {
  font-weight: bold;
  margin-bottom: 1rem;
}
.install-prompt-container .install-prompt .prompt-content .not-safari {
  margin-top: 1rem;
}
.install-prompt-container .install-prompt .prompt-content .cta {
  margin: 2rem 0;
}
.install-prompt-container .install-prompt .prompt-content .icon {
  height: 18px;
  width: auto;
}
.terms-modal .terms-content {
  width: 100%;
  min-height: 30rem;
  grid-column: span 3;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.terms-modal .scrollable-container {
  width: 98%;
  height: 20rem;
  overflow-y: scroll;
  margin: 2rem 0;
  padding: 5px;
}
.terms-modal .scrollable-container::-webkit-scrollbar {
  background-color: #f5f3f3;
  width: 5px;
  border-radius: 0 5px 5px 0;
}

.terms-modal .scrollable-container::-webkit-scrollbar-thumb {
  background: #d60909;
  width: 2px;
  min-height: 50px;
  border-radius: 20px;
}
.terms-modal .agreement {
  margin-bottom: 2rem;
}
.terms-modal .agreement input[type="checkbox"] {
  cursor: pointer;
  opacity: 0;
  position: absolute;
}

.terms-modal .agreement label {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #9b9696;
}

.terms-modal .agreement label::before {
  content: "";
  width: 1rem;
  height: 1rem;
  border: 1px solid #9b9696;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.terms-modal .agreement label:hover::before,
input[type="checkbox"]:hover + label::before {
  border: 1px solid #d60909;
}

.terms-modal .agreement input[type="checkbox"]:focus + label::before {
  border: 1px solid #d60909;
}

.terms-modal .agreement input[type="checkbox"]:checked + label::before {
  content: "\f00c";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  font-size: 0.7rem;
  color: #d60909;
  border: 1px solid #d60909;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.terms-modal .agreement input[type="checkbox"]:checked + label {
  color: #d60909;
  /* font-weight: bold; */
}
.terms-modal .btn {
  margin-bottom: 1rem;
}
/* Terms of Use */
.terms-doc {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 0.9rem;
}
.terms-doc .title {
  font-size: 1.2rem;
  font-weight: bold;
}
.terms-doc .subheader {
  font-weight: bold;
  font-size: 1.1rem;
}
.terms-doc .bold {
  font-weight: bold;
  font-size: 1rem;
}
.terms-doc .small-bold {
  font-weight: bold;
  color: #242424;
}
.terms-doc ol,
.terms-doc ul {
  margin: 0 2rem;
}
.terms-doc .underline-bold-italic {
  font-weight: bold;
  font-style: italic;
  text-decoration: underline;
}
.terms-doc .underline {
  text-decoration: underline;
}
.terms-doc .underline-bold {
  font-weight: bold;
  text-decoration: underline;
}
.terms-doc .italic {
  font-style: italic;
}
.terms-doc .italic-bold {
  font-weight: bold;
  font-style: italic;
}
.terms-doc table {
  width: 90%;
  text-align: left;
  border-collapse: collapse;
  align-self: center;
}
.terms-doc table td,
.terms-doc table th {
  border: 1px solid;
  vertical-align: top;
  padding: 5px;
}

/* utils */
.copy-button {
  background-color: #d60909;
  color: #fff;
  padding: 2px 10px;
  text-align: center;
  border-radius: 5px;
  border: 1px solid #d60909;
}
.copy-button.copied {
  color: #d60909;
  background-color: #fff;
}
.copy-button:hover {
  cursor: pointer;
}

/* Media Queries */

/* Small devices (portrait tablets and large phones, 608px and up) */
@media only screen and (min-width: 608px) {
  #root {
    font-size: 15px;
  }

  html {
    font-size: 15px;
  }

  input {
    font-size: 16px !important;
  }
  .shell-container {
    font-size: 15px;
  }
  .container {
    padding: 1rem;
  }
  /* Modals */
  .modal-container .modal-box {
    width: 80%;
  }
  .modal-container .modal-content {
    padding: 0.7rem;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .container {
    max-width: 800px;
  }
  h2 {
    font-size: 1.5rem;
  }
  h3 {
    font-size: 1.3rem;
  }
  h4 {
    font-size: 1.2rem;
  }
  h5 {
    font-size: 1rem;
  }

  /* Landing */
  .swiper .swiper-button-prev,
  .swiper .swiper-button-next {
    color: #9b9696;
    display: block;
  }

  /* Alerts */
  .alert {
    max-width: 800px;
  }

  /* Modals */
  .modal-container .modal-box {
    width: 75%;
  }
  .modal-container .modal-box .modal-content {
    padding: 1.2rem;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .navbar {
    height: 75px;
  }
  .navbar .logo h1 {
    font-size: 2rem;
    line-height: 2.1rem;
  }
  .navbar .landing-links {
    font-size: 1.1rem;
  }
  .login-container h1,
  .register-container h1 {
    font-size: 2rem;
  }
  /* Notifications */
  .notifications-container {
    top: 73px;
  }
}

/* Landscape Orientation */
@media only screen and (orientation: landscape) {
  .modal-container {
    position: fixed;
    top: 82.375px;
    left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
    z-index: 10;
    width: 100%;
    height: 100vh;
  }
  .modal-container .modal-box {
    top: 10%;
    height: auto;
  }
}
.options-container {
  .search-input {
    border: none !important;
    &:focus::-webkit-input-placeholder {
      color: #9b9696 !important;
    }
  }
}
.is-placeholder {
  color: #9b9696 !important;
}

.custom-search-dropdown {
  .selected {
    background-color: #fff;
    max-height: unset;
    .selected-text {
      white-space: unset !important;
      flex-wrap: wrap;
      gap: 8px;
    }
  }
}
.dropdown-label {
  margin-top: 5px;
  color: var(--primary-color);
  position: relative;
  top: 10px;
}
.options-container .option.highlighted {
  background-color: #eaeaea;
}

/* Below is added to fix the issue in development when iframe is added on top of all elements when we make change to code*/
body > iframe {
  display: none;
}
.d-flex {
  display: flex !important;
}
.align-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}

.chip {
  display: flex;
  align-items: center;
  margin-right: 5px;
  padding: 6px 12px;
  background-color: #f0f0f0;
  border-radius: 16px;
  font-size: 14px;
  white-space: nowrap;
}
.w-100 {
  width: 100%;
}
.info-box-icon {
  color: #d60909;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;
  align-self: flex-start;
}

.info-tooltip {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
  top: 35px;
  width: 80%;
  right: 5%;
  border: 1px solid #d60909;
  border-radius: 10px;
  padding: 1rem;
  white-space: pre-wrap;
  z-index: 5;
}
.relative {
  position: relative;
}
.cursor-pointer {
  cursor: pointer;
}
