/* Navbar */
.navbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  padding: 12px 16px;
  height: 66px;
  border-bottom: solid 2px #d60909;
}
.navbar ul {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.navbar a {
  padding: 0.5rem 0;
  margin: 0 0.25rem;
  color: #fff;
}
.navbar a:hover {
  color: #d60909;
}
.navbar .landing-links {
  display: none;
}
.navbar .logo {
  line-height: 1.5rem;
  font-size: 1.2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  color: #fff;
}
.navbar .logo .id {
  color: #d60909;
}
.navbar .logo:hover {
  cursor: pointer;
}
.navbar .landing-dropdown {
  display: block;
}
.navbar .landing-dropdown .icon-box {
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar .landing-dropdown .account-icon::before {
  font-size: 2rem;
}
.navbar .landing-dropdown .account-icon:hover {
  cursor: pointer;
  color: #d60909;
}
.navbar .landing-dropdown .dropdown-box {
  position: absolute;
  top: 64px;
  right: 0;
  width: 10rem;
  height: auto;
  background-color: #242424;
  display: flex;
  flex-direction: column;
  padding-bottom: 0.5rem;
  z-index: 10;
}
.navbar .landing-dropdown .dropdown-box .option {
  margin: 0.25rem 1rem;
  padding: 0.25rem;
  border-bottom: 1px solid #242424;
}
.navbar .landing-dropdown .dropdown-box .option:hover {
  border-bottom: 1px solid #d60909;
  cursor: pointer;
}
.navbar .landing-dropdown .dropdown-box .option a {
  padding: 0;
  margin: 0;
}
.navbar .landing-dropdown .dropdown-box .option a:hover {
  color: #fff;
}

/* Account Icon */
.navbar .account-dropdown .icon-box {
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 9;
}
.navbar .account-dropdown .icon-box .notification-icon {
  position: absolute;
  font-size: 0.6rem;
  color: #fff;
  background-color: #d60909;
  top: -7px;
  left: -12px;
  width: 1.2rem;
  height: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.navbar .account-dropdown .account-icon::before {
  font-size: 2rem;
}
.navbar .account-dropdown .account-icon:hover {
  cursor: pointer;
  color: #d60909;
}
.navbar .account-dropdown .dropdown-box {
  position: absolute;
  top: 64px;
  right: 0;
  width: 11rem;
  height: auto;
  background-color: #242424;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  z-index: 10;
}
.navbar .account-dropdown .dropdown-box.full {
  width: 100%;
  z-index: 10;
  animation-duration: 500ms;
  animation-name: fullwidth;
}
@keyframes fullwidth {
  from {
    margin-left: 100%;
    width: 0%;
  }
  to {
    margin-left: 0%;
    width: 100%;
  }
}

.navbar .account-dropdown .dropdown-box.half {
  width: 90%;
  max-width: 600px;
  animation-duration: 500ms;
  animation-name: notifications;
}
@keyframes notifications {
  from {
    margin-left: 100%;
    width: 0%;
  }
  to {
    margin-left: 0%;
    width: 90%;
  }
}
.navbar .account-dropdown .dropdown-box .dropdown-box-content {
  width: 11rem;
  height: auto;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
}
.navbar .navIcons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.navbar .navIcons .prof-icon {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  border: 2px solid #fff;
  margin-right: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar .navIcons .prof-icon:hover, .navbar .navIcons .timeline-icon:hover {
  cursor: pointer;
}
.navbar .navIcons .prof-icon img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}
.navbar .navIcons .timeline-icon {
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
}
.navbar .navIcons .prof-icon i::before, .navbar .navIcons .timeline-icon i::before {
  width: 100%;
  font-size: 1.5rem;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  padding: auto;
}
.navbar .account-dropdown .dropdown-box .option {
  margin: 0.25rem 1rem;
  padding: 0.25rem;
  border-bottom: 1px solid #242424;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.navbar .account-dropdown .dropdown-box .option .notification-icon {
  color: #d60909;
  font-weight: bold;
  margin-left: 0.5rem;
}
.navbar .account-dropdown .dropdown-box .option:hover {
  border-bottom: 1px solid #d60909;
  cursor: pointer;
}
.navbar .account-dropdown .dropdown-box .option a {
  padding: 0;
  margin: 0;
}
.navbar .account-dropdown .dropdown-box .option a:hover {
  color: #fff;
}
.navbar .account-dropdown .dropdown-box .option.promotion, .navbar .account-dropdown .dropdown-box .option.events {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  white-space: nowrap;
}
.navbar .account-dropdown .dropdown-box .option.promotion .text, .navbar .account-dropdown .dropdown-box .option.events .text {
  font-style: italic;
  font-size: 0.9rem;
  color: #d60909;
}
.navbar .account-dropdown .dropdown-box .option.promotion .promo-name, .navbar .account-dropdown .dropdown-box .option.events .event-name {
  max-width: calc(100%);
  text-overflow: ellipsis;
  overflow: hidden;
}
.navbar .account-dropdown .dropdown-box .profile-options {
  width: 100%;
  margin: 0.25rem 0;
    padding: 0.25rem;
    border-bottom: 1px solid #242424;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.navbar .account-dropdown .dropdown-box .profile-options .profile-link {
  width: 100%;
  margin: 0.25rem 0;
  border-bottom: 1px solid #242424;
}
.navbar .account-dropdown .dropdown-box .profile-options .profile-link:hover {
  cursor: pointer;
}
.navbar .account-dropdown .dropdown-box .profile-options .profile-link .link {
  width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.25rem 0 0.25rem 1rem;
    margin: 0;
}
.navbar .account-dropdown .dropdown-box .profile-options .profile-link .profile-icon {
  height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
    border: 2px solid #fff;
    margin-right: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.navbar .account-dropdown .dropdown-box .profile-options .profile-link .profile-icon:hover {
  cursor: pointer;
}

.navbar .account-dropdown .dropdown-box .profile-options .profile-link .profile-icon img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}

.navbar .account-dropdown .dropdown-box .profile-options .profile-link .profile-icon i::before {
  width: 100%;
  font-size: 0.5rem;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  padding: auto;
}
.navbar .account-dropdown .dropdown-box .profile-options .profile-link .profile-icon .other-prof-notification {
  position: absolute;
  font-size: 0.6rem;
  color: #fff;
  background-color: #d60909;
  top: -7px;
  left: -12px;
  width: 1.2rem;
  height: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.navbar .account-dropdown .dropdown-box .profile-options .profile-link .text {
  width: 80%;
  font-size: 0.9rem;
  white-space: nowrap;
}
.navbar .account-dropdown .dropdown-box .profile-options .profile-link .text p {
max-width: calc(100%);
  text-overflow: ellipsis;
  overflow: hidden;
}

.navbar .account-dropdown .search {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.navbar .account-dropdown .search i {
  font-size: 0.9rem;
  margin-right: 0.3rem;
}
.navbar .account-dropdown .dropdown-box .closeFull {
  position: absolute;
  height: 63px;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -63px;
  right: 0;
  padding: 0 16px;
  background-color: #242424;
  color: #d60909;
}
.navbar .account-dropdown .dropdown-box .closeFull i:hover {
  cursor: pointer;
}
/* Notifications Box (Athlete and Coach) */
.notifications-container {
  position: absolute;
  color: #242424;
  background-color: #fff;
  width: 100%;
  top: 0;
  right: 0;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-top: 2px solid #d60909;
  z-index: 11;
}
.notifications-container::-webkit-scrollbar {
  background-color: #f5f3f3;
  width: 5px;
  border-radius: 0 5px 5px 0;
}
.notifications-container::-webkit-scrollbar-thumb {
  background: #d60909;
  width: 2px;
  min-height: 50px;
  border-radius: 20px;
}

.notifications-container .notification {
  width: 100%;
  background-color: #f5f3f3;
  padding: 0.5rem;
  border-bottom: 1px solid #9b9696;
  display: grid;
  grid-template-columns: 2fr 1fr;
  position: relative;
}
.notifications-container .notification .text {
  grid-row: 1;
  grid-column: span 2;
  font-weight: bold;
  width: 85%;
}
.notifications-container .notification .date {
  grid-row: 2;
  grid-column: 1 / 2;
  font-style: italic;
  font-size: 0.8rem;
  align-self: center;
}
.notifications-container .notification .view {
  grid-row: 2;
  grid-column: 2 / 3;
  align-self: center;
  justify-self: flex-end;
  background-color: #d60909;
  font-size: 0.8rem;
  color: #fff;
  padding: 0.05rem 0.8rem;
  border-radius: 15px;
  margin: 0.25rem 0;
}
.notifications-container .notification .view:hover,
.notifications-container .notification .buttons .upgrade:hover,
.notifications-container .notification .buttons .leave:hover,
.notifications-container .notification .buttons .deny:hover,
.notifications-container .notification .buttons .accept:hover,
.notifications-container .notification .leave-options .option:hover {
  cursor: pointer;
}
.notifications-container .notification .buttons {
  grid-row: 3;
  grid-column: 1 / 3;
  justify-self: flex-end;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.notifications-container .notification .buttons .upgrade, .notifications-container .notification .buttons .confirm, .notifications-container .notification .buttons .accept {
  background-color: #d60909;
  font-size: 0.8rem;
  color: #fff;
  padding: 0.05rem 0.8rem;
  border: 1px solid #d60909;
  border-radius: 15px;
  margin: 0.25rem 0 0.25rem 0.5rem;
}
.notifications-container .notification .buttons .leave, .notifications-container .notification .buttons .cancel, .notifications-container .notification .buttons .deny {
  background-color: #fff;
  font-size: 0.8rem;
  color: #d60909;
  padding: 0.05rem 0.8rem;
  border: 1px solid #d60909;
  border-radius: 15px;
  margin: 0.25rem 0.5rem 0.25rem 0;
}
.notifications-container .notification .buttons .leave.read {
  display: none;
}
.notifications-container .notification.read {
  background-color: #fff;
}
.notifications-container .notification.read .text {
  font-weight: normal;
}
.notifications-container .notification.read .view,
.notifications-container .notification.read .buttons .upgrade {
  background-color: #fff;
  color: #d60909;
  border: none;
}
.notifications-container .notification.read .view:hover,
.notifications-container .notification.read .upgrade:hover {
  text-decoration: underline;
  cursor: pointer;
}
.notifications-container .empty {
  margin-top: 2rem;
  font-style: italic;
}
/* Confirm Leave Coach Group Buttons */
.notifications-container .notification .leave-options {
  position: absolute;
  background-color: #242424;
  opacity: 0.9;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.notifications-container .notification .leave-options .option {
  font-size: 0.8rem;
  padding: 0.05rem 0.8rem;
  border: 1px solid #d60909;
  border-radius: 15px;
  margin: 0.25rem 0.5rem;
}
.notifications-container .notification .leave-options .confirm {
  color: #fff;
  background-color: #d60909;
}
.notifications-container .notification .leave-options .cancel {
  background-color: #fff;
  color: #d60909;
}
/* Groups box in navbar (coaches */
.groups-container {
  position: absolute;
  background-color: #fff;
  color: #242424;
  width: 100%;
  top: 0;
  right: 0;
  height: 225px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-top: 2px solid #d60909;
  padding: 1rem;
  z-index: 11;
}
.groups-container.extended {
  height: 302px;
}

/* Coach Search (in athlete profile) */
.coach-search-container {
  position: absolute;
  background-color: #242424;
  width: 100%;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 11;
}

.coach-search-container .searchbar-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.coach-search-container .searchbar-content .searchbar-prompt {
  font-size: 0.9rem;
  margin-bottom: 1rem;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.coach-search-container input {
  display: block;
  width: 100%;
  padding: 0.1rem 0.5rem;
  font-size: 1rem;
  border: solid 1px #d60909;
  border-radius: 5px;
  font: inherit;
  margin: 0.05rem 0;
}

.coach-search-container ::-webkit-input-placeholder,
.coach-search-container :-ms-input-placeholder,
.coach-search-container ::-ms-input-placeholder,
.coach-search-container ::placeholder {
  color: #242424;
  opacity: 1;
}

.coach-search-container input:focus {
  outline: none;
  border: 2px solid #d60909;
  border-radius: 5px;
}
.coach-search-container input:focus::placeholder {
  color: transparent;
}
.coach-search-container .searchbar-content .searchbox {
  width: 95%;
  max-width: 620px;
  position: relative;
}
.coach-search-container .searchbar-content .autofill-box {
  position: absolute;
  background-color: #fff;
  color: #242424;
  width: 98%;
  max-height: 10rem;
  overflow-y: scroll;
  top: 31px;
  left: 1%;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 10;
}
.coach-search-container .searchbar-content .autofill-box::-webkit-scrollbar {
  background-color: #fff;
  width: 5px;
  border-radius: 0 5px 5px 0;
}
.coach-search-container
  .searchbar-content
  .autofill-box::-webkit-scrollbar-thumb {
  background-color: #d60909;
  width: 1px;
  min-height: 50px;
  border-radius: 20px;
}
.coach-search-container .searchbar-content .autofill-box .suggested {
  width: calc(100% - 5px);
  min-height: 50px;
  display: grid;
  grid-template-columns: 1fr 3fr 3fr 0.75fr;
  padding: 0.3rem 0.5rem;
  font-size: 0.8rem;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #f5f3f3;
  color: #242424;
  margin: 0;
}
.coach-search-container .searchbar-content .autofill-box .suggested:hover {
  cursor: pointer;
  background-color: #f5f3f3;
}
.coach-search-container .searchbar-content .autofill-box .suggested .name {
  grid-row: 1;
  grid-column: 2 / 4;
  font-weight: bold;
  max-width: 100%;
  line-height: 1.2rem;
  overflow-wrap: normal;
}
.coach-search-container .searchbar-content .autofill-box
.suggested
.profile {
  width: 30px;
  height: 40px;
  grid-column: 1 / 2;
  grid-row: span 3;
  justify-self: center;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.coach-search-container .searchbar-content .autofill-box
.suggested
.profile img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.coach-search-container .searchbar-content .autofill-box .suggested .role {
  grid-row: 2;
  grid-column: 2 / 4;
  font-size: 0.7rem;
  font-style: italic;
  line-height: 0.9rem;
}
.coach-search-container
  .searchbar-content
  .autofill-box
  .suggested
  .organisation {
    grid-row: 3;
  grid-column: 2 / 5;
  justify-self: flex-end;
  line-height: 0.9rem;
  font-size: 0.7rem;
}
.coach-search-container .searchbar-content .autofill-box .suggested .sports {
  grid-row: 3;
  grid-column: 2 / 4;
  width: 100%;
  font-size: 0.7rem;
  line-height: 0.9rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
}
.coach-search-container .searchbar-content .autofill-box .suggested .sports .sport {
  padding: 1px 10px;
    background-color: #9b9696;
    color: #fff;
    border-radius: 15px;
    margin-right: 0.5rem;
    margin-top: 5px;
}

.coach-search-container .searchbar-content .autofill-box .suggested .country {
  grid-row: 1 / 3;
  grid-column: 4 / 5;
  align-self: center;
  justify-self: flex-end;
}
.coach-search-container .searchbar-content .autofill-box .suggested .country p {
  display: none;
}
.coach-search-container
  .searchbar-content
  .autofill-box
  .suggested
  .country
  .country-flag {
    font-size: 1.5rem;
    line-height: 1.5rem;
}
.coach-search-container .searchbar-content .autofill-box .message {
  padding: 1rem 0.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.coach-search-container .searchbar-content .advanced {
  position: absolute;
  bottom: 10px;
  right: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #d60909;
}
.coach-search-container .searchbar-content .advanced:hover {
  cursor: pointer;
  text-decoration: underline;
}
.coach-search-container .searchbar-content .advanced i {
  margin-left: 0.5rem;
}
.navbar .following-modal {
  position: absolute;
}

/* Media Queries */

/* Small devices (portrait tablets and large phones, 608px and up) */
@media only screen and (min-width: 608px) {
  .navbar .navIcons .timeline-icon {
    margin-right: 1.5rem;
  }
    .navbar .navIcons .prof-icon {
      margin-right: 2.5rem;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  /* Navbar */
  .navbar .landing-links {
    display: flex;
    flex-direction: row;
    width: 30%;
    max-width: 12rem;
    justify-content: space-between;
  }
  .navbar .landing-dropdown {
    display: none;
  }
    .navbar .navIcons .prof-icon {
      margin-right: 3rem;
    }
        .navbar .navIcons .timeline-icon {
          margin-right: 2rem;
        }
        .navbar .navIcons .prof-icon i::before,
        .navbar .navIcons .timeline-icon i::before {
          font-size: 2rem;
        }
        
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .navbar {
    height: 75px;
  }
  .navbar .logo h1 {
    font-size: 2rem;
    line-height: 2.1rem;
  }
  .navbar .landing-links {
    font-size: 1.1rem;
  }
  /* Notifications */
  .navbar .account-dropdown .dropdown-box {
    top: 73px;
  }
}
